<template>
  <section class="hero-container" :class="{'no-vh-adjustment': noVhAdjustment}">

    <!-- Header main -->
    <nav class="header header-main">

      <div class="navbar-brand">
        <a href="/" class="is-flex is-align-items-center">
          <img src="../../../assets/landing-pages/new/logo.svg" alt="Liffery Logo" class="logo-img"/>
          <h1 class="logo-title is-inline-block ml-2">
            Liffery
          </h1>
        </a>
      </div>


      <div class="is-flex is-align-items-center navbar-action-controls">
        <img
          @click="loginPrompt"
          src="../../../assets/landing-pages/new/icons/add-item.svg"
          alt="Add item"
          class="navbar-icon"
        />
        <img
          @click="loginPrompt"
          src="../../../assets/landing-pages/new/icons/shopping-bag.svg"
          alt="Shopping list"
          class="navbar-icon shopping-list-icon"
        />

        <m-login-button-trigger
          v-if="!isBusinessLandingPage"
          classes="is-outlined is-hidden-mobile"
        />

        <a v-else :href="accountHref" class="is-hidden-mobile">
          <b-button class="is-outlined" type="is-primary" @click="accountsClick">
            Business Login
          </b-button>
        </a>

        <a role="button"
           class="navbar-burger is-hidden-tablet"
           aria-label="menu"
           aria-expanded="false"
           data-target="mainNavbar"
           @click="openSidebar = true"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <b-sidebar
        type="is-light"
        :fullheight="true"
        :fullwidth="false"
        :overlay="true"
        :right="true"
        v-model="openSidebar"
      >
        <m-header-seo-sidebar-content v-on:close="openSidebar = false"/>
      </b-sidebar>
    </nav>

    <!-- Header top -->
    <nav class="header header-top">
      <div class="is-flex is-align-items-center">
        <router-link
          class="header-item"
          :class="{'active-item': [routeNames.ROUTE_INFO_WHAT_IS_AUTHED, routeNames.ROUTE_INFO_WHAT_IS].includes($route.name)}"
          :to="{name:  isAuthenticated ? routeNames.ROUTE_INFO_WHAT_IS_AUTHED : routeNames.ROUTE_INFO_WHAT_IS }">
          {{ $t('footer.forPeople') }}
        </router-link>

        <router-link
          class="header-item"
          :class="{'active-item': $route.name === routeNames.ROUTE_INFO_BUSINESS}"
          :to="{name: routeNames.ROUTE_INFO_BUSINESS}">
          {{ $t('footer.forBusiness') }}
        </router-link>

        <router-link
          class="header-item"
          :class="{'active-item': $route.name === routeNames.ROUTE_INFO_LANDING_PAGES_PERSONAL_SHOPPER_CONSUMER}"
          :to="{name: routeNames.ROUTE_INFO_LANDING_PAGES_PERSONAL_SHOPPER_CONSUMER}">
          Personal Product Researchers
        </router-link>

      </div>
      <div class="is-flex is-align-items-center language-switcher">
        <b-dropdown
          v-if="showLanguageSwitcher"
          append-to-body
          aria-role="menu"
          scrollable
          trap-focus
          :mobile-modal="false"
          position="is-bottom-left"
        >
          <template #trigger>
            <a
              class="navbar-item"
              role="button">
              <div class="is-flex is-align-items-center">
               <span>
                  <!-- <span class="lng-text">{{ $t('language.' + currentLanguage) }}</span>-->
                  <span :class="'fi-' + currentLanguageISOAlpha2" class="fi"></span>
                </span>
                <chevron-down-icon size="1.25x"/>
              </div>
            </a>
          </template>

          <b-dropdown-item
            v-for="(language, index) in languages"
            v-bind:key="index"
            aria-role="menuitem"
            :separator="language === 'DIVIDE'"
            @click="() => language !== 'DIVIDE' && changeLanguage(language)"
          >
            <template v-if="language !== 'DIVIDE'">
              <span :class="'fi-' + localeToISOA2(language)" class="fi"></span>
              {{ $t('language.' + language) }}
            </template>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </nav>

    <slot name="default"></slot>

    <m-header-login-trigger/>
  </section>
</template>

<style scoped lang="scss">
@import './MHeaderSeo.scss';
</style>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ChevronDownIcon } from 'vue-feather-icons';
import MHeaderSeoSidebarContent from '@/components/molecules/landing/MHeaderSeoSidebarContent.vue';
import { RouteNames } from '@/router/RouteNames';
import { AuthenticationStore } from '@/store';
import MHeaderLoginTrigger from '@/components/molecules/MHeaderLoginTrigger.vue';
import config from '@/config';
import { translation } from '@/plugins/i18n/Translation';
import setLanguage from '@/utils/setLanguage';
import { intersperse } from 'common-utils/array';
import ALink from '@/components/atoms/link/ALink.vue';
import MUserProfileHeader from '@/components/molecules/MUserProfileHeader.vue';
import MLoginButtonTrigger from '@/components/molecules/buttons/MLoginButtonTrigger.vue';
import Cookie from '@/services/Cookies';
import { Language } from '@/api/ms-authentication/services/interfaces/User';
import { getDateInXMonths } from 'common-utils/time';
import urlHashParamGet from '@/utils/urlHashParamGet';
import { CookieNames } from '@/enums/CookieNames';

@Component({
  components: {
    MLoginButtonTrigger,
    MUserProfileHeader,
    ALink,
    MHeaderLoginTrigger,
    ChevronDownIcon,
    MHeaderSeoSidebarContent
  }
})
export default class MHeaderSeo extends Vue {
  @Prop({ default: true })
  noVhAdjustment!: boolean;

  showLanguageSwitcher = false;

  openSidebar = false;

  accountHref = config.accountsHref;

  routeNames = RouteNames;

  localeToISOA2 = translation.localeToISOA2;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get currentLanguageISOAlpha2 () {
    return translation.currentLanguageISOAlpha2;
  }

  get languages () {
    return intersperse(translation.supportedLanguages, 'DIVIDE');
  }

  get isBusinessLandingPage () {
    return this.$route.name === RouteNames.ROUTE_INFO_BUSINESS;
  }

  get isAuthenticated () {
    return AuthenticationStore.getAuthenticated;
  }

  async created () {
    await this.languageSetFromPersist();
  }

  loginPrompt () {
    AuthenticationStore.TOGGLE_PROMPT_LOGIN({ state: true });
  }

  accountsClick () {
    window.location.href = this.accountHref;
  }

  async changeLanguage (language: Language, bypassCookie = false) {
    await setLanguage(language);
    if (!bypassCookie) {
      await this.languagePersist(language);
    }
    // add the langauge to the url for people to share
    urlHashParamGet('lang', { setValue: language });
  }

  async languagePersist (language: Language) {
    await Cookie.set(CookieNames.LANGUAGE_SWITCHER, language, { expires: getDateInXMonths(18), });
  }

  async languageSetFromPersist () {
    // set the cookie based lang
    const language = await Cookie.get(CookieNames.LANGUAGE_SWITCHER) as Language;
    if (language && this.currentLanguage !== language) {
      await this.changeLanguage(language, true);
    } else {
      const hashLang = urlHashParamGet('lang') as Language;
      if (hashLang && Object.values(Language).includes(hashLang)) {
        await this.changeLanguage(hashLang);
      }
    }
  }
}
</script>
